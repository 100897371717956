<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">人脸活跃度检测</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc operationControlExamination" style="align-items: flex-start">
          <div class="searchbox" style="display: flex">
            <div title="班级搜索" style="display: flex; align-items: center" class="searchboxItem ci-full">
              <span class="itemLabel" style="
                  min-width: 5rem;
                  text-align: right;
                  font-size: 14px;
                  padding: 0 0.5rem;
                ">班级搜索:</span>
              <el-select v-model="projectId" placeholder="请选择" remote size="small" clearable
                :remote-method="superPorjectSelect" @visible-change="clearProjectSearchModel">
                <template>
                  <div class="select-header">
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      ">班级编号</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      ">班级名称</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="seaProjectCode" v-on:input="superPorjectSelect" type="text" size="small"
                      placeholder="班级编号" clearable style="width: 150px" />
                    <el-input v-model="seaProjectName" v-on:input="superPorjectSelect" type="text" size="small"
                      placeholder="请输入班级名称" clearable style="margin-left: 50px; width: 250px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in projectList" :key="index" :label="item.projectName"
                  :value="item.projectId">
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span style="width: 250px; margin-left: 50px; font-size: 13px">{{ item.projectName }}</span>
                </el-option>
              </el-select>
            </div>
            <div title="学员搜索" style="display: flex; align-items: center" class="searchboxItem ci-full">
              <span class="itemLabel" style="
                  min-width: 5rem;
                  text-align: right;
                  font-size: 14px;
                  padding: 0 0.5rem;
                ">学员搜索:</span>
              <el-select v-model="userId" placeholder="请选择" no-data-text="没有数据" remote size="small" clearable
                :remote-method="superUserSelect" @visible-change="clearUserSearchModel">
                <template>
                  <div class="select-header">
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      ">学员姓名</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      ">身份证号码</span>
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      ">学员电话</span>
                  </div>
                  <div class="select-header">
                    <el-input v-model="seaUserName" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="学员姓名" clearable style="width: 100px" />
                    <el-input v-model="seaUserIdcard" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="身份证号码" clearable style="margin-left: 50px; width: 140px" />
                    <el-input v-model="seaUserMobile" v-on:input="superUserSelect" type="text" size="small"
                      placeholder="学员电话" clearable style="margin-left: 50px; width: 100px" />
                  </div>
                </template>
                <el-option v-for="(item, index) in userList" :key="index" :label="item.userName" :value="item.userId">
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span style="width: 140px; margin-left: 50px; font-size: 13px">{{ item.idcard }}</span>
                  <span style="width: 100px; margin-left: 50px; font-size: 13px">{{ item.mobile }}</span>
                </el-option>
              </el-select>
            </div>

            <div title="结业状态" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 80px">结业状态:</span>
              <el-select v-model="graduationState" placeholder="请选择" size="small">
                <el-option label="全部" :value="''"></el-option>
                <el-option label="已结业" :value="true"></el-option>
                <el-option label="未结业" :value="false"></el-option>
              </el-select>
            </div>
            <!-- <div title="检测时间" class="searchboxItem ci-full">
              <span class="itemLabel">检测时间:</span>
              <el-date-picker v-model="dateList" size="small" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" value="yyyy-MM-dd" value-format="yyyy-MM-dd" />
            </div> -->
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            <el-button type="primary" class="bgc-bv" round @click="doExport('10')">导出(xls)</el-button>
            <el-button type="primary" class="bgc-bv" round @click="doExport('20')">导出(pdf)</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="60" fixed :index="indexMethod" />
              <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" width="80" fixed />
              <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" width="160" />
              <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" width="120" />
              <el-table-column label="课程名称" align="left" prop="courseName" width="300" />
              <el-table-column label="课节名称" align="left" prop="kpointName" width="300" />
              <el-table-column label="检测时间" align="left" prop="checkTime" />
              <el-table-column label="照片" align="left" prop="approveFace" width="150">
                <template slot-scope="scope">
                  <el-image style="width: 50px; height: 50px" :src="scope.row.approveFace"
                    :preview-src-list="[scope.row.approveFace]"></el-image>
                </template>
              </el-table-column>
              <Empty3 slot="empty"><span>请选择班级后查询</span></Empty3>
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty3 from "@/components/Empty3.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "operate_facialActivityDetection",
  components: {
    Empty3,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      projectName: "",
      idCard: "",
      paperName: "",
      userName: "",
      userId: "",
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      projectId: "",
      seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],

      graduationState: true,
      dateList: [],
    };
  },
  doNotInit: true,
  created() {
    this.superUserSelect();
    this.superPorjectSelect();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  watch: {
    projectId: function () {
      this.superUserSelect();
    },
    userId: function () {
      this.superPorjectSelect();
    },
  },
  methods: {
    superUserSelect(e) {
      this.$post(
        "/biz/user/companyUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    superPorjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
          userId: this.userId,
        },
        3000,
        false
      ).then((res) => {
        // this.projectList = res.data.list;
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    clearProjectSearchModel(e) {
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superPorjectSelect();
      }
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    cancleForm() {
      this.userId = "";
      this.projectId = "";
      this.projectName = "";
      this.paperName = "";
      this.getData();
    },
    // 导出
    doExport(exportType) {
      if (!this.projectId) {
        this.$message.error('请选择班级后导出！')
        return
      }
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      ).then(() => {
        let params = {
          userId: this.userId || "",
          projectId: this.projectId || "",
          graduationState: this.graduationState,
          exportType
        };
        if (this.dateList?.length === 2) {
          params.checkStartDate = this.dateList[0];
          params.checkEndDate = this.dateList[1];
        }
        this.$post("/biz/project/face/activation/export", params).then(
          (res) => {
            if (res.status == "0") {
              const arr = [...res.data];
              for (let item of arr) {
                if (!this.downloadItems.includes(item.taskId)) {
                  this.$store.dispatch("pushDownloadItems", item.taskId);
                } else {
                  this.$message.warning(
                    "[" + item.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              }
            } else {
              this.$message.error(res.message);
            }
          }
        );
      });
    },
    getData(pageNum = 1) {
      if (!this.projectId) {
        this.$message.error('请选择班级后查询！')
        return
      }
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        userId: this.userId || "",
        projectId: this.projectId || "",
        graduationState: this.graduationState,
      };
      if (this.dateList?.length === 2) {
        params.checkStartDate = this.dateList[0];
        params.checkEndDate = this.dateList[1];
      }
      this.doFetch({
        url: "/biz/project/face/activation/pageList",
        params,
        pageNum,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}

.operationControlExamination {
  >div {
    justify-content: space-between;
  }

  .el-form {
    display: flex;

    .el-form-item {
      margin-bottom: 0;
    }

    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }

  .searchboxs {
    justify-content: flex-start;
  }
}
</style>